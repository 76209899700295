import React from "react";
import { Typography, Box, Grid, Chip } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";

import companyPlaceholder from "../../../../../images/CompanyPlaceholder.png";
import EsgGptNewCompanyDetails from "../../esgGptAddNewCompany/EsgGptNewCompanyDetails";
import useHoverEffect from "../../../../../customHooks/getHoveringEffects";

import SectorImg from "../../../../../images/profileTopSectors.png";
import IndustryImg from "../../../../../images/profileTopIndustries.png";
import RegionImg from "../../../../../images/profileTopRegions.png";
import ESGScoreImg from "../../../../../images/profileTopESGScore.png";
import ControversyImg from "../../../../../images/profileTopControversyScore.png";
import ChipCard from "./CompanyInfoComps/ChipCard";
import TextCard from "./CompanyInfoComps/TextCard";
import ImageCard from "./CompanyInfoComps/ImageCard";
import ScoreCard from "./CompanyInfoComps/ScoreCard";

export function HeadingCard({
  primaryHead,
  secondaryHead,
  justifyCenter = false,
  isInsight = true,
  year = false,
  is_premium,
  color,
  children,
}) {
  const { isHovering, handleMouseEnter, handleMouseLeave } = useHoverEffect();
  // console.log(is_premium);

  return (
    <div
      style={{
        border: "1px solid #F6F6F6",
        width: "100%",
        height: "50px",
        backgroundColor: color ? color : "#FBFBFB",
        borderRadius: "8px",
        display: "flex",
        justifyContent: justifyCenter
          ? "center"
          : isInsight
          ? "flex-start"
          : "space-between",
        alignItems: "center",
        marginBottom: "5px",
      }}
      onMouseEnter={() => handleMouseEnter(justifyCenter)}
      onMouseLeave={() => handleMouseLeave(justifyCenter)}
    >
      {children}
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: secondaryHead ? 400 : 600,
          color: "#242424",
          padding: "12px",
          lineHeight: "20px",
        }}
      >
        {primaryHead}
      </Typography>
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: 600,
          color: "#242424",
          padding: "12px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontWeight: 400,
            color: "#242424",
            paddingLeft: "2px",
            filter: is_premium ? "blur(0px)" : "blur(5px)",
          }}
        >
          {secondaryHead}
        </span>
        {secondaryHead && !year && (
          <span
            style={{
              fontSize: "13px",
              fontWeight: 400,
              color: "#242424",
              paddingLeft: "2px",
            }}
          >
            {` / ${100}`}
          </span>
        )}
      </Typography>
    </div>
  );
}

function EsgGptCompanyInfo({ esgScore, esgCScore, is_premium }) {
  const { newOrganization, mainDrawer } = useSelector(
    (state) => state.esgGptReducer
  );

  // console.log(newOrganization?.ESGScore, " ------EsgGptCompanyInfo------");

  // card data with type and content
  const cardData = [
    // company name card
    { type: "text", content: newOrganization?.name, isMain: true },

    // sector card
    {
      type: "chips",
      content: {
        heading: "Sectors",
        chips: newOrganization?.sector || [],
        css: {
          mainBg: "#EBF1F8",
          headingColor: "#3374B9",
          chipBg: "#C0D4E9",
          chipColor: "#15314E",
        },
        image: SectorImg,
      },
    },

    // reporting framework card
    {
      type: "chips",
      content: {
        heading: "Reporting Framework",
        chips: ["BRSR"],
        css: {
          mainBg: "#F6F6F6",
          headingColor: "#0F0F0F",
          chipBg: "#E9E9E9",
          chipColor: "#676767",
        },
        isSub: true,
      },
    },

    // company logo card
    { type: "image", content: newOrganization?.logo || companyPlaceholder },

    // Industries card
    {
      type: "chips",
      content: {
        heading: "Industries",
        chips: newOrganization?.industry || [],
        image: IndustryImg,
        css: {
          mainBg: "#EBF6EF",
          headingColor: "#32A05F",
          chipBg: "#BFE2CD",
          chipColor: "#154328",
        },
      },
    },

    // esg score card
    {
      type: "score",
      content: {
        title: "ESG Score ",
        value: newOrganization?.ESGScore,
        image: ESGScoreImg,
      },
    },

    // market cap card
    { type: "text", content: newOrganization?.revenue, isMain: false },

    // regions card
    {
      type: "chips",
      content: {
        heading: "Regions",
        chips: ["India"],
        image: RegionImg,
        css: {
          mainBg: "#F6F6F6",
          headingColor: "#15314E",
          chipBg: "#E9E9E9",
          chipColor: "#676767",
        },
      },
    },

    // controversy score card
    {
      type: "score",
      content: {
        title: "Digital Reputation Score",
        value: newOrganization?.esgc_score || "-",
        image: ControversyImg,
      },
    },
  ];

  // Calculate the number of columns dynamically based on the number of cards
  const numColumns = 3;

  // Split the cardData into arrays of cards for each column
  const columnData = Array.from({ length: numColumns }, (_, i) =>
    cardData.filter((_, index) => index % numColumns === i)
  );

  return (
    <Box
      height="35em"
      style={{
        margin: "0 0 1em",
        display: "flex",
        flexWrap: "wrap",
        width: mainDrawer ? "78vw" : "98vw",
      }}
    >
      {columnData.map((column, columnIndex) => (
        <Box
          key={columnIndex}
          style={{
            flex: "1 0 calc(33.33% - 1em)",
            maxWidth: "calc(33.33% - 1em)",
            margin: "0.5em",
          }}
        >
          {column.map((card, index) => (
            <Box
              key={index}
              bgcolor={
                card?.type === "chips" ? card?.content?.css?.mainBg : "#F6F6F6"
              }
              borderRadius={10}
              padding={2}
              style={{ marginBottom: "1em" }}
            >
              {card?.type === "text" && (
                <TextCard isMain={card?.isMain} content={card?.content} />
              )}
              {card?.type === "image" && <ImageCard content={card?.content} />}
              {card?.type === "chips" && <ChipCard content={card?.content} />}
              {card?.type === "score" && (
                <ScoreCard is_premium={is_premium} content={card?.content} />
              )}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}

// {/* <>
// <div
// style={{
//   display: "flex",
//   justifyContent: "space-evenly",
//   alignItems: "center",
//   height: "100%",
//   // backgroundColor:"red"
// }}
// >
// <img
//   src={newOrganization?.logo || companyPlaceholder}
//   width={"15%"}
//   height={"80%"}
//   alt="star"
//   // style={{border:"1px solid rgb(245,245,245,0)"}}
// />
// <div style={{ width: "45%", height: "90%" }}>
//   <EsgGptNewCompanyDetails is_premium={is_premium} />
// </div>
// <div
//   style={{
//     width: "30%",
//     height: "90%",
//     padding: "5px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-evenly",
//     gap: "10px",
//     // border:"solid"
//   }}
// >
//   <HeadingCard
//     primaryHead={"ESG Score"}
//     secondaryHead={`${esgScore?.toFixed(2) || 0}`}
//     isInsight={false}
//     is_premium={is_premium}
//   />
//   <HeadingCard
//     primaryHead={"ESG Controversy Score"}
//     secondaryHead={`${esgCScore?.toFixed(2) || 0}`}
//     isInsight={false}
//     is_premium={is_premium}
//   />
//   <HeadingCard
//     primaryHead={"Year"}
//     year={true}
//     secondaryHead={newOrganization?.year}
//     isInsight={false}
//     is_premium={is_premium}
//   />
// </div>
// </div>
// </> */}
export default EsgGptCompanyInfo;
