import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CSVLink, CSVDownload } from "react-csv";

import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import moduleIcon from "../../../../images/Module.png";

import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";

import EsgGptCompanyInfo, {
  HeadingCard,
} from "./EsgGptCompany/EsgGptCompanyInfo";
import { TopicHeader, TopicData } from "../../esgCompanyProfile/esgComponents";
import ScoreChart from "../../esgCompanyProfile/ScoreChart";
import { OverallRiskCard } from "../../esgCompanyProfile/overallProfile";
import thumbUp from "../../../../images/thumbUp.svg";
import thumbDown from "../../../../images/thumbDown.svg";
import LockIcon from "../../../../images/lockIcon.png";
import copySvg from "../../../../images/copy.svg";
import more from "../../../../images/more.svg";
import share from "../../../../images/share.svg";
import heart from "../../../../images/heart.png";
import companyPlaceholder from "../../../../images/CompanyPlaceholder.png";

import PercentageDoughnutChart from "../../../UI/percentageDoughnut.js/percentageDoughnutChart";
import CustomButton from "../../../UI/button/button";
import Charts, { LineCharts } from "./EsgGptCompany/SampleCharts";
import EsgGptChatMessageLayout from "../esgGptChat/EsgGptChatMessageLayout";

import {
  getCompanyInsights,
  getScores,
  getPeerScores,
} from "../../../../redux/actions/riskEvaluator/riskEvaluatorActions";
import { ListItemLink } from "../EsgGptCompanyList";
import CustomCompaniesRange from "../../../UI/CustomRange/CustomCompaniesRange";
import { EsgAnainsPeersChart } from "../../charts/charts";
import chevronsLeft from "../../../../images/chevronsLeft.png";
import airTable from "../../../../images/airtable.png";
import freetrial from "../../../../images/freetrail.png";
import mcd from "../../../../images/mcd.png";
import useChat from "../esgGptChat/useChat";
import {
  getGptChatHIstory,
  setChatConversations,
  setDisclaimer,
} from "../../../../redux/actions/gpt/esgGptAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getControversyLinks } from "../../../../redux/actions/riskdashboard/riskDashboardActions";
import EnergyCharts from "./EsgGptCompany/EnergyCharts";
import CompanyLoading from "../CompanyLoading";
import { Avatar, Button, Chip } from "@material-ui/core";
import {
  gptPeerAnalysisText,
  returnCategoryOfdataPoint,
} from "../../../../utils/riskUtils/riskUtils";

// Socket imports
import {
  connectSocket,
  connectSocketCompany,
  socket,
  socketCompany,
} from "../../../../socket";
import UpdatedDisclaimer from "./EsgCompanyRightComps/UpdatedDisclaimer";
import PlanExpired from "../PlanExpired";
import Feedback from "../Feedback";
import { setSignupStepNo } from "../../../../redux/actions/login/loginAction";
import { set_snack_bar } from "../../../../redux/actions/snackbar/snackbar_action";
import DisclaimerDialog from "../esgGptChat/genericChatComps/ChatDisclaimer/DisclaimerDialog";
import {
  PeerSectionOne,
  PeerSectionTwo,
} from "./EsgCompanyRightComps/PeerAnalysis";
import GrayBackgroundCard from "../../../UI/backgroundCard/GrayBackgroundCard";

// icons
import { FiUsers } from "react-icons/fi";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
import { ChatPlayGround } from "../EsgGptPlayground";
import { LockIconDialog } from "./EsgGptCompany/CompanyInfoComps/ScoreCard";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#EBF1F8",
    // color: theme.palette.common.black,
    fontWeight: 500,
    color: "#15314E",
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    color: "#15314E",
  },
}))(TableCell);

export const useStyles = makeStyles({
  table: {
    // minWidth: 700,
    border: "1px solid #A1BFDF",
    borderRadius: "8px",
    marginTop: "10px",
    width: "100%",
    "& .MuiTableCell-root": {
      // color: "white",
      borderCollapse: "separate",
    },
  },
  tableCell: {
    padding: "5px",
  },
  titleRow: {
    backgroundColor: "#fff",
  },
  typoColorSecondary: {
    color: "#11184F",
  },
  suggestion: {
    backgroundColor: "#f6f6f6",
    border: `1px solid "#EAEEF5"`,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
  },
  dialog: {
    maxWidth: 550,
    position: "absolute",
    backgroundColor: "white",
    borderRadius: 8,
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    padding: 10,
    boxShadow: "1px 1px 4px grey",
  },
});

export function BlueBackgroundCard({
  heading,
  subHeading,
  sideHeading,
  is_premium,
  handleClosePricingDialog,
}) {
  return (
    <div
      style={{
        // height: "50px",
        width: "100%",
        backgroundColor: "#EBF1F8",
        display: "flex",
        flexDirection: sideHeading || is_premium === false ? "row" : "column",
        alignItems: is_premium === false ? "center" : "flex-start",
        marginTop: "10px",
        justifyContent: "space-between",
      }}
    >
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#15314E",
          lineHeight: "20px",
          padding: subHeading ? "15px 15px 10px 15px" : "15px",
        }}
      >
        {heading}
      </Typography>
      {subHeading && (
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: 400,
            color: "#676767",
            padding: "0px 15px 15px 15px",
          }}
        >
          {subHeading}
        </Typography>
      )}
      {sideHeading && (
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            color: "#15314E",
            padding: "15px",
            marginLeft: "auto",
          }}
        >
          {subHeading || sideHeading}
        </Typography>
      )}
      {is_premium === false && (
        <Button
          variant="contained"
          style={{
            color: "#1976D2",
            backgroundColor: "white",
            marginRight: "10px",
          }}
          size="small"
          onClick={() => handleClosePricingDialog(heading)}
          disableElevation={true}
        >
          View Details
        </Button>
      )}
    </div>
  );
}

export function KpiPresentation({
  chartsArr,
  width = "100%",
  chartWidth = "48%",
  is_premium,
  kpiChartdata,
  x_title_1,
  x_title_2,
  y_title,
  chart1,
  chart2,
}) {
  const classes = useStyles();
  const csvData = kpiChartdata
    ? [
        ["Topics", "Current Year"],
        ...Object.keys(kpiChartdata).map((v) => [v, kpiChartdata[v]]),
      ]
    : [];

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {/* {kpiChartdata &&
        Object.entries(kpiChartdata).map((ent) => {
          return (
            <div style={{ width: chartWidth, height: "300px" }}>
              <EnergyCharts kpiChartdata={ent} />

             <Charts /> 
            </div>
          );
        })} */}
      <div style={{ height: "400px", width: "100%" }}>
        {
          <EnergyCharts
            kpiChartdata={chart1}
            is_premium={is_premium}
            x_title={x_title_1}
            y_title={y_title}
          />
        }
      </div>

      <div style={{ height: "400px", width: "100%" }}>
        {
          <EnergyCharts
            kpiChartdata={chart2}
            is_premium={is_premium}
            x_title={x_title_2}
            y_title={y_title}
          />
        }
      </div>

      {is_premium && (
        <div style={{ width }}>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton
              style={{ textTransform: "capitalize", textDecoration: "none" }}
              color="primary"
              variant="text"
              startIcon={<LaunchIcon />}
            >
              <CSVLink
                style={{ textDecoration: "none" }}
                data={csvData}
                filename={"dataTable.csv"}
              >
                Export Table
              </CSVLink>
            </CustomButton>
          </div>
          <TableContainer
            style={{
              padding: "3px",
              // width:"70%"
            }}
            component={"div"}
          >
            <Table
              className={classes.table}
              classes={classes.root}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow style={{}}>
                  <StyledTableCell className={classes.tableCell}>
                    Topics
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableCell}>
                    Current Year
                  </StyledTableCell>
                  {/* <StyledTableCell className={classes.tableCell}>
                  Previous Year
                </StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {kpiChartdata &&
                  Object.entries(kpiChartdata).map(([key, value]) => (
                    <TableRow
                      key={key}
                      className={true ? classes.titleRow : classes.topicRow}
                    >
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: true ? 10 : 8,
                        }}
                      >
                        {key}
                      </StyledTableCell>

                      <StyledTableCell className={classes.tableCell} style={{}}>
                        {is_premium ? value : <LockIconDialog />}
                      </StyledTableCell>

                      {/* <StyledTableCell className={classes.tableCell}>
                    {row.calories}
                  </StyledTableCell> */}
                    </TableRow>
                  ))}
                {/* {rows.map((row) => (
                <TableRow
                  key={row.name}
                  className={row.srNo ? classes.titleRow : classes.topicRow}
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: row.srNo ? 10 : 8,
                    }}
                  >
                    {row.srNo}
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableCell}>
                    {row.name}
                  </StyledTableCell>
               <StyledTableCell className={classes.tableCell}>
                    {row.calories}
                  </StyledTableCell> 
                </TableRow>
              ))} */}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

export function EsgInsightCard({ topic, insight = [] }) {
  // console.log("EsgInsightCard", insight);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        marginTop: 10,
      }}
    >
      <OverallRiskCard
        // topic={topic.topic}
        // heading={topic.heading}
        insight={insight}
        gpt={true}
      />
      {false &&
        insight &&
        insight.length > 0 &&
        insight.map((topic, i) => {
          let type = Object.keys(topic)[0];
          let topicHeader = Object.values(topic)[0];
          // console.log("topicHeader", i, type);
          let category = returnCategoryOfdataPoint(type);
          return (
            <OverallRiskCard
              topic={category || "Environment"}
              heading={type}
              description={topicHeader}
              gpt={true}
              key={i}
            />
          );
        })}
      {false &&
        insight.length > 0 &&
        insight.map(([key, value], index) => {
          let type = key;
          let topicHeader = value;
          // console.log("topicHeader", i, type);
          let category = returnCategoryOfdataPoint(type);
          return (
            <OverallRiskCard
              topic={category || "Environment"}
              heading={type}
              description={topicHeader}
              gpt={true}
              key={index}
            />
          );
        })}
    </div>
  );
}

export function EsgInsightMain({
  title,
  area,
  color,
  descriptionComponent,
  children,
}) {
  // console.log("EsgInsightMain", { title, area, color, children });
  return (
    <div style={{ padding: "15px" }}>
      <HeadingCard primaryHead={title} color={color}>
        <IconButton
          variant="outlined"
          style={{
            borderColor: "#FBFBFB",
            backgroundColor: color,
            marginLeft: "10px",
            padding: "5px",

            borderRadius: 5,
          }}
          size="small"
        >
          {children}
        </IconButton>
      </HeadingCard>
      {descriptionComponent}

      <EsgInsightCard insight={area} />
    </div>
  );
}

export function MoreActionButtons(props) {
  const { chatId, answer } = props;
  const [openFeedBackDialog, setOpenFeedBackDialog] = React.useState(false);
  const [dislike, setDislike] = React.useState(false);
  // console.log("MoreActionButtons", props, chatId);
  const dispatch = useDispatch();

  const handleCloseFeedBackDialog = (value) => {
    if (value) {
      setDislike(true);
      setOpenFeedBackDialog((preValue) => !preValue);
    } else {
      setOpenFeedBackDialog((preValue) => !preValue);
      setDislike(false);
    }
  };

  const handleCopyText = () => {
    if (answer) {
      navigator.clipboard.writeText(answer);
      dispatch(set_snack_bar(true, "Text copied to clipboard!"));
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {answer && (
          <IconButton
            variant="contained"
            style={{
              marginLeft: "10px",
              padding: "5px",
              borderRadius: 5,
            }}
            size="small"
            onClick={handleCopyText}
          >
            <img
              src={copySvg}
              style={{
                margin: "-5px",
                width: "50px",
                height: "50px",
                cursor: "pointer",
              }}
              alt="copy icon"
            />
          </IconButton>
        )}
        <IconButton
          variant="contained"
          style={{
            // borderColor: "#FBFBFB",
            // backgroundColor: "#EDF2F3",
            marginLeft: "10px",
            padding: "5px",

            borderRadius: 5,
          }}
          size="small"
          onClick={() => handleCloseFeedBackDialog(false)}
        >
          <img src={thumbUp} style={{ cursor: "pointer" }} alt="pdf icon" />
        </IconButton>
        <IconButton
          variant="contained"
          style={{
            // borderColor: "#FBFBFB",
            // backgroundColor: "#EDF2F3",
            marginLeft: "10px",
            padding: "5px",

            borderRadius: 5,
          }}
          size="small"
          onClick={() => handleCloseFeedBackDialog(true)}
        >
          <img src={thumbDown} style={{ cursor: "pointer" }} alt="pdf icon" />
        </IconButton>
      </div>
      {openFeedBackDialog && (
        <Feedback
          isOpen={openFeedBackDialog}
          handleClose={handleCloseFeedBackDialog}
          additional={dislike}
          chatId={chatId}
          title={"Please Provide Additional Feedback"}
          contentText={
            "What was the issue with the response ? How could it be improved ?What was the issue with the response ? How could it be improved ?"
          }
          extraText={"Let us help you better on the basis of your rating"}
          placeHolderText={
            dislike
              ? "What do you not like about the response ?"
              : "What do you like about the response ?"
          }
        />
      )}
    </>
  );
}

function EsgGptCompanyRight() {
  const login = useSelector((state) => state.login);

  const history = useHistory();

  const { location } = history;

  const is_company_based = location.pathname === "/esg_gpt/chat" ? false : true;

  const riskEvaluator = useSelector((state) => state.riskEvaluator);

  const { newOrganization, selectedChat } = useSelector(
    (state) => state.esgGptReducer
  );
  const { controversyLinks } = useSelector((state) => state?.riskDashboard);
  const {
    scoreObject,
    esgScore,
    esgcScore,
    energy_kpis,
    water_kpis,
    renewable_energy,
    non_renewable_energy,
    water_withdrawl,
    water_consumption,
  } = {
    ...riskEvaluator?.scores,
  };
  const { environment, social, governance } = { ...scoreObject };
  // console.log("newOrganization", newOrganization);
  const { peer_companies, year_wise_data } = {
    ...riskEvaluator?.peerScores,
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("signup_process_no")) === 3) {
      dispatch(setSignupStepNo(4));
    }
  }, []);

  const dispatch = useDispatch();

  const [openPricingDialog, setOpenPricingDialog] = React.useState(false);
  const [sectionName, setSectionName] = React.useState(false);

  const handleClosePricingDialog = (source) => {
    setSectionName(source);
    setOpenPricingDialog((preValue) => !preValue);
  };

  React.useEffect(() => {
    if (newOrganization?.isin) {
      dispatch(
        getScores(
          login.token,
          newOrganization?.isin,
          newOrganization?.year || ""
        )
      );
      dispatch(
        getCompanyInsights(
          login.token,
          newOrganization?.isin,
          newOrganization?.year
        )
      );
      dispatch(
        getPeerScores(
          login?.token,
          newOrganization?.isin,
          newOrganization?.industry_code,
          newOrganization?.year
        )
      );
      dispatch(
        getGptChatHIstory(
          login?.token,
          newOrganization?._id === undefined ? "" : newOrganization?._id,
          is_company_based,
          selectedChat?._id === undefined ? "" : selectedChat?._id
        )
      );

      dispatch(getControversyLinks(login.token, newOrganization?.isin, 5, 0));
    }

    return () => {
      dispatch(setChatConversations([]));
    };
  }, [newOrganization?.isin]);

  // temporary

  const dataNew = [
    {
      topic: "Environment",
      score: scoreObject?.environment?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Energy",
          score: scoreObject?.environment?.data_points?.energy?.toFixed(2),
        },
        {
          topic: "Water",
          score: scoreObject?.environment?.data_points?.water?.toFixed(2),
        },
        {
          topic: "Waste",
          score: scoreObject?.environment?.data_points?.waste?.toFixed(2),
        },
        {
          topic: "Analytic CO2",
          score: scoreObject?.environment?.data_points?.analytic?.toFixed(2),
        },
        {
          topic: "Green revenues",
          score: scoreObject?.environment?.data_points?.green?.toFixed(2),
        },
      ],
    },
    {
      topic: "Social",
      score: scoreObject?.social?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Diversity and Inclusion",
          score: scoreObject?.social?.data_points?.diversity?.toFixed(2),
        },
        {
          topic: "Human Rights",
          score: scoreObject?.social?.data_points?.humanRights?.toFixed(2),
        },
        {
          topic: "Career development and training",
          score: scoreObject?.social?.data_points?.career?.toFixed(2),
        },
        {
          topic: "Health and Safety",
          score: scoreObject?.social?.data_points?.health?.toFixed(2),
        },
        {
          topic: "Data privacy",
          score: scoreObject?.social?.data_points?.dataPrivacy?.toFixed(2),
        },
        {
          topic: "Responsible Marketing",
          score: scoreObject?.social?.data_points?.product?.toFixed(2),
        },
      ],
    },
    {
      topic: "Governance",
      score: scoreObject?.governance?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Structure",
          score: scoreObject?.governance?.data_points?.structure?.toFixed(2),
        },
        {
          topic: "Compensation",
          score: scoreObject?.governance?.data_points?.compensation?.toFixed(2),
        },
      ],
    },
  ];

  const is_premium = JSON.parse(localStorage.getItem("is_premium"));

  return (
    <div
      style={{
        height: newOrganization?.loader ? "100%" : "88%",
        width: "100%",
        overflow: "scroll",
        marginBottom: "1%",
        padding: "5px",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <EsgGptCompanyInfo
          esgScore={esgScore}
          esgCScore={esgcScore}
          is_premium={is_premium}
        />
      </div>
      {newOrganization?.loader ? (
        <CompanyLoading margin={2} />
      ) : (
        <>
          <GrayBackgroundCard
            heading={"ESG Profile With ESG Score"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
            Icon={<FiUsers color={"#3374B9"} size={20} />}
          />
          {/* <BlueBackgroundCard
            heading={"ESG Profile With ESG Score"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
          /> */}
          <div
            style={{
              // height: "95%",
              width: "100%",
              padding: "15px",
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: "5px",
                width: "60%",
                // border:"solid"
              }}
            >
              {[...dataNew, 1].map((data, index, arr) => {
                const lastElement = index === arr.length - 1;
                return (
                  <Fragment key={`uniqueKey${index}`}>
                    {lastElement ? (
                      <div style={{ width: "48%" }} key={index}>
                        <BlueBackgroundCard heading={"ESG Score"} />
                        <Typography
                          style={{
                            fontSize: "20px",
                            fontWeight: 600,
                            color: "#242424",
                            padding: "10px",
                          }}
                        >
                          {esgScore?.toFixed(2)}%
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#6C6C6C",
                            padding: "10px",
                          }}
                        >
                          ESG scores provide a measure of a company's
                          environmental, social, and governance performance.
                          Various criteria are used to evaluate companies on
                          these three dimensions. Third-party ESG rating
                          agencies offer different methodologies and scoring
                          systems
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ width: "48%" }} key={index}>
                        <TopicHeader
                          topic={data.topic}
                          score={data.score}
                          is_premium={is_premium}
                        />
                        {data.subTopic.map((sub, index) => (
                          <TopicData
                            topic={sub.topic}
                            score={sub.score}
                            key={index}
                            is_premium={is_premium}
                          />
                        ))}
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                padding: "50px 20px 50px 10px",
                // border:"solid",
                width: "35%",
              }}
            >
              {riskEvaluator?.scores && (
                <ScoreChart
                  esgScore={esgScore}
                  scores={{ ...riskEvaluator?.scores }}
                  is_premium={is_premium}
                />
              )}
            </div>
          </div>

          <GrayBackgroundCard
            heading={"Digital Reputation Score"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
            Icon={<FiUsers color={"#3374B9"} size={20} />}
          />
          {/* <BlueBackgroundCard
            heading={" ESG Controversy Score (ESGC)"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
          /> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "48%",
                padding: "15px",
              }}
            >
              <TopicHeader hasLogo={false} topic={"Online Footprint Score"} />
              {/* <HeadingCard primaryHead={"Online Footprint Score"} /> */}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ padding: 10 }}>
                  <img src={heart} alt="heart" />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "20%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#ECA270",
                        borderRadius: "100%",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                    <Typography style={{ fontSize: 13, padding: 10 }}>
                      Online Foot Print Score.
                    </Typography>
                  </div>
                  {/* <Typography
                    style={{ fontSize: 13, fontWeight: 600, padding: 10 }}
                  >
                    {`${
                      Math.round(riskEvaluator?.scores?.esgcScore) || 0
                    } / 100`}
                  </Typography> */}
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      color: "#242424",
                      padding: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {is_premium ? (
                      <span
                        style={{
                          fontSize: "13px",
                          fontWeight: 600,
                          color: "#242424",
                          paddingLeft: "2px",
                          filter: is_premium ? "blur(0px)" : "blur(5px)",
                        }}
                      >
                        {Math.round(riskEvaluator?.scores?.esgcScore) || 0}
                      </span>
                    ) : (
                      <LockIconDialog />
                    )}
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: 600,
                        color: "#242424",
                        paddingLeft: "2px",
                      }}
                    >
                      {` / ${100}`}
                    </span>
                  </Typography>
                </div>

                <div>
                  <PercentageDoughnutChart
                    color="#ECA270"
                    emptyColor="#F9EEED"
                    footPrint={true}
                    percentage={
                      Math.round(riskEvaluator?.scores?.esgcScore) || 0
                    }
                    is_premium={is_premium}
                  />
                </div>
              </div>

              {controversyLinks && controversyLinks.length > 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography style={{ fontSize: 13 }}>
                      These search findings illuminate the ongoing controversies
                      and divergent viewpoints within the corporate landscape
                      regarding your ESG policies. Investigate the varying
                      opinions on how businesses should navigate and respond to
                      environmental challenges, delving into controversies
                      surrounding corporate environmental responsibility and
                      sustainability from a company-centric perspective
                    </Typography>
                    {/* <Typography style={{ fontSize: 13, marginTop: 8 }}>
                    Based on our market insights and benchmarking tool, is in
                    the second quartile of the Peer group range and has
                    significant scope to improve.
                  </Typography>
                  <Typography style={{ fontSize: 13, marginTop: 8 }}>
                    Our curated sustainability solutions are designed to help
                    you progress your company’s sustainability goals to the next
                    level.
                  </Typography> */}
                  </div>
                  <div></div>
                </div>
              )}
            </div>
            <div
              style={{
                width: "48%",
                padding: "15px",
              }}
            >
              {/* <HeadingCard primaryHead={"Reference Link"} /> */}
              <TopicHeader hasLogo={false} topic={"Reference Link"} />

              <List
                style={{
                  height: "325px",
                  overflow: "scroll",
                  width: true ? "100%" : "430px",
                }}
              >
                {controversyLinks &&
                  controversyLinks.map((v, index) => {
                    return (
                      <ListItemLink
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 5,
                          borderBottom: "1px solid #C0D4E9",
                        }}
                        key={index}
                      >
                        <ListItemText
                          style={{ alignSelf: "flex-start" }}
                          primary={
                            <div>
                              <Typography
                                variant="body2"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  color: "#000000",
                                }}
                              >
                                {v?.title}
                              </Typography>
                              <Chip
                                style={{
                                  color:
                                    v?.sentiment_label === "Negative"
                                      ? "#682822"
                                      : v?.sentiment_label === "Positive"
                                      ? "#154328"
                                      : "",

                                  backgroundColor:
                                    v?.sentiment_label === "Negative"
                                      ? "#F9EEED"
                                      : v?.sentiment_label === "Positive"
                                      ? "#BFE2CD"
                                      : "",
                                }}
                                // color="primary"
                                avatar={
                                  <Avatar
                                    style={{
                                      color:
                                        v?.sentiment_label === "Negative"
                                          ? "#682822"
                                          : v?.sentiment_label === "Positive"
                                          ? "#154328"
                                          : "",

                                      backgroundColor:
                                        v?.sentiment_label === "Negative"
                                          ? "#682822"
                                          : v?.sentiment_label === "Positive"
                                          ? "#154328"
                                          : "",
                                      width: "10px",
                                      height: "10px",
                                    }}
                                  >
                                    .
                                  </Avatar>
                                }
                                label={v?.sentiment_label}
                              />
                            </div>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: 11,
                                color: "#6F7170",
                                width: "80%",
                                overflow: "hidden",
                              }}
                            >
                              {v?.url}
                            </Typography>
                          }
                        />
                        <ListItemAvatar style={{ alignSelf: "flex-start" }}>
                          <img
                            src={v?.socialimage || companyPlaceholder}
                            style={{ padding: "0px 5px" }}
                            width={"70px"}
                            height={"100%"}
                            alt="star"
                          />
                        </ListItemAvatar>
                        {/* 
                        <ListItemAvatar style={{ padding: 5 }} align="center">
                          <IconButton
                            style={{ padding: 5 }}
                            edge="end"
                            aria-label="delete"
                          >
                            <LaunchIcon
                              onClick={() => window.open(v.url)}
                              style={{ color: "#3374B9" }}
                            />
                          </IconButton>
                        </ListItemAvatar> */}
                      </ListItemLink>
                    );
                  })}
              </List>
            </div>
          </div>

          <GrayBackgroundCard
            heading={"ESG KPI Data Breakdown With Data Representation"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
            Icon={<FiUsers color={"#3374B9"} size={20} />}
          />

          {/* <BlueBackgroundCard
            heading={"ESG KPI Data Breakdown With Data Representation"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
          /> */}

          <div style={{ padding: "15px" }}>
            <TopicHeader hasLogo={false} topic={"Energy"}>
              <img src={moduleIcon} alt="lockedScore" />
            </TopicHeader>

            <KpiPresentation
              chartsArr={[1, 2]}
              kpiChartdata={energy_kpis}
              chart1={renewable_energy}
              chart2={non_renewable_energy}
              is_premium={is_premium}
              y_title={"Energy Consumption (in Joules or multiples)"}
              x_title_1={"Renewable Energy Consumption"}
              x_title_2={"Non Renewable Energy Consumption"}
            />
          </div>
          <div style={{ padding: "15px" }}>
            <TopicHeader hasLogo={false} topic={"Water"}>
              <img src={moduleIcon} alt="lockedScore" />
            </TopicHeader>
            <KpiPresentation
              kpiChartdata={water_kpis}
              chartsArr={[1]}
              // width={"65%"}
              // chartWidth={"33%"}
              chart1={water_consumption}
              chart2={water_withdrawl}
              is_premium={is_premium}
              y_title={"Volume (in Kilolitres)"}
              x_title_1={"Water Consumption"}
              x_title_2={"Water Withdrawal"}
            />
          </div>

          <GrayBackgroundCard
            heading={"ESG Insights"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
            Icon={<HiOutlineSquares2X2 color={"#3374B9"} size={20} />}
          />

          {/* <BlueBackgroundCard
            heading={"ESG Insights"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
          /> */}

          <EsgInsightMain
            title={"ESG Risk Area"}
            area={riskEvaluator?.overallData?.["weak_areas_summary"]}
            color={"#F9EEED"}
            descriptionComponent={
              <HeadingCard
                year={true}
                secondaryHead={true}
                primaryHead={
                  riskEvaluator?.overallData?.["improvement_areas_summary"]
                    ? "These are the area of risks & edentification of emerging ESG risks and potential regulatory changes. With impact percentage total of "
                    : "We are currently gathering ESG-related data on the company’s strengths, risks, and improvement areas. Once our assessment is complete, we will update it here."
                }
              />
            }
          >
            <img src={moduleIcon} alt="lockedScore" />

            {/* <ReportProblemOutlinedIcon
              style={{ color: "#FBFBFB" }}
              fontSize="small"
            /> */}
          </EsgInsightMain>

          <EsgInsightMain
            title={"Strong Area"}
            area={riskEvaluator?.overallData?.["strong_areas_summary"]}
            color={"#EBF6EF"}
            descriptionComponent={
              <HeadingCard
                year={true}
                secondaryHead={true}
                primaryHead={
                  riskEvaluator?.overallData?.["improvement_areas_summary"]
                    ? "These are the area of risks & edentification of emerging ESG risks and potential regulatory changes. With impact percentage total of "
                    : "We are currently gathering ESG-related data on the company’s strengths, risks, and improvement areas. Once our assessment is complete, we will update it here."
                }
              />
            }
          >
            <img src={moduleIcon} alt="lockedScore" />
            {/* <CheckOutlinedIcon style={{ color: "#FBFBFB" }} fontSize="small" /> */}
          </EsgInsightMain>
          <EsgInsightMain
            title={"Improvement Area"}
            area={riskEvaluator?.overallData?.["improvement_areas_summary"]}
            color={"#EBF1F8"}
            descriptionComponent={
              <HeadingCard
                year={true}
                secondaryHead={true}
                primaryHead={
                  riskEvaluator?.overallData?.["improvement_areas_summary"]
                    ? "These are the area of risks & edentification of emerging ESG risks and potential regulatory changes. With impact percentage total of "
                    : "We are currently gathering ESG-related data on the company’s strengths, risks, and improvement areas. Once our assessment is complete, we will update it here."
                }
              />
            }
          >
            {/* <KeyboardArrowUpOutlinedIcon
              style={{ color: "#FBFBFB" }}
              fontSize="small"
            /> */}
            <img src={moduleIcon} alt="lockedScore" />
          </EsgInsightMain>

          {/* peer analysis section */}
          <GrayBackgroundCard
            heading={"Peer Analysis"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
            Icon={<HiOutlineSquares2X2 color={"#3374B9"} size={20} />}
          />

          {/* <BlueBackgroundCard
            heading={"Peer Analysis"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
          /> */}

          {/* Peer Analysis Main Container */}
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            style={{ gap: 50 }}
          >
            {/* Chart 1 and Text Data */}
            <PeerSectionOne
              is_premium={is_premium}
              riskEvaluator={riskEvaluator}
            />

            {/* Peer Analysis Dynamic Table */}
            <PeerSectionTwo is_premium={is_premium} data={peer_companies} />
          </Box>

          <div
            style={{
              width: "100%",
              height: "380px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // flexWrap: "wrap",
            }}
          >
            <div
              style={{
                width: "90%",
                height: "300px",
                filter: is_premium ? "blur(0px)" : "blur(5px)",
              }}
            >
              {/* <EsgAnainsPeersChart /> */}

              <Charts isPeer={true} peer_companies={peer_companies} />
            </div>
          </div>
          {/* <div
            style={{
              width: "100%",
              height: "350px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // flexWrap: "wrap",
            }}
          >
            <div
              style={{
                width: "90%",
                height: "300px",
                filter: is_premium ? "blur(0px)" : "blur(5px)",
              }}
            >
              <LineCharts year_wise_data={year_wise_data} />
            </div>
          </div> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#242424",
                padding: "15px",
              }}
            >
              {/* Would you like to share these data */}
              Would you like to give us feedback on the above data
            </Typography>
            {/* <MoreActionButtons chatId={chatId} answer={answer}  /> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#242424",
                padding: "15px",
              }}
            >
              {`Ask a question about ${newOrganization?.name}...`}
            </Typography>
          </div>
          <div style={{ padding: "15px" }}>
            <ChatPlayGround companyName={newOrganization?.name} />
          </div>
        </>
      )}
      {openPricingDialog && (
        <PlanExpired
          isOpen={openPricingDialog}
          handleClose={handleClosePricingDialog}
          sourceName={sectionName}
        />
      )}
    </div>
  );
}

export default EsgGptCompanyRight;
