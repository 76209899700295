import { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";

import CustomButton from "../../../../../UI/button/button";
import companyPlaceholder from "../../../../../../images/CompanyPlaceholder.png";
import LockIcon from "../../../../../../images/lockIcon.png";
import { CSVLink } from "react-csv";
import { LockIconDialog } from "../../EsgGptCompany/CompanyInfoComps/ScoreCard";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    border: "1px solid #A1BFDF",
    borderRadius: 8,
  },
  stickyFirstColumn: {
    position: "sticky",
    left: 0,
    zIndex: 1,
  },
  actionButtons: {
    borderRadius: 10,
    textTransform: "capitalize",
  },
}));

const PeerSectionTwo = ({ data = [], is_premium, children }) => {
  const { mainDrawer } = useSelector((state) => state.esgGptReducer);
  const classes = useStyles();

  // State to track which view to show: "all", "environment", "social", "governance"
  const [view, setView] = useState("all");

  // Function to generate headers based on the view
  const generateHeaders = () => {
    let headers = ["Revenue", "ESG Score", "Digital Reputation Score"];
    if (view === "all") {
      const environmentKeys = data[0]?.environment
        ? Object.keys(data[0].environment)
        : [];
      const socialKeys = data[0]?.social ? Object.keys(data[0].social) : [];
      const governanceKeys = data[0]?.governance
        ? Object.keys(data[0].governance)
        : [];
      headers = [
        "Company",
        ...headers,
        ...environmentKeys,
        ...socialKeys,
        ...governanceKeys,
      ];
    } else {
      headers = ["Company", ...headers, ...Object.keys(data[0]?.[view] || {})];
    }
    return headers;
  };

  // Function to generate rows based on the view
  const generateRows = () => {
    return data?.map((company, indx) => {
      let rowData = [company.revenue, company.score, company.esgc_score];

      if (view === "all") {
        const environmentValues = company.environment
          ? Object.values(company.environment)
          : [];
        const socialValues = company.social
          ? Object.values(company.social)
          : [];
        const governanceValues = company.governance
          ? Object.values(company.governance)
          : [];
        rowData = [
          company.company_name,
          ...rowData,
          ...environmentValues,
          ...socialValues,
          ...governanceValues,
        ];
      } else {
        rowData = [
          company.company_name,
          ...rowData,
          ...Object.values(company[view] || {}),
        ];
      }

      // Fill in missing values with "-"
      if (rowData.length < generateHeaders().length) {
        const missingCount = generateHeaders().length - rowData.length;
        rowData = [...rowData, ...Array(missingCount).fill("-")];
      }

      return (
        <TableRow
          key={company._id}
          style={{ backgroundColor: indx % 2 === 0 ? "#F6F6F6" : "#fff" }}
        >
          {rowData?.map((item, index) => (
            <TableCell
              key={index}
              className={index === 0 ? classes.stickyFirstColumn : ""}
              style={{ backgroundColor: indx % 2 === 0 ? "#F6F6F6" : "#fff" }}
            >
              {index === 0 ? (
                <Box display={"flex"} style={{ gap: 10 }}>
                  <img
                    src={data[indx]?.logo || companyPlaceholder}
                    alt="companyLogo"
                    style={{
                      width: 30,
                      aspectRatio: "1:1",
                      objectFit: "contain",
                    }}
                  />
                  {item}
                </Box>
              ) : (
                <Box
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#6C6C6C",
                  }}
                >
                  {is_premium ? (
                    typeof item === "number" ? (
                      item.toFixed(2)
                    ) : (
                      item || "-"
                    )
                  ) : (
                    <LockIconDialog />
                  )}
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  };

  const generateRowsCsv = () => {
    const rows = data?.map((company, indx) => {
      let rowData = [company.revenue, company.score, company.esgc_score];

      if (view === "all") {
        const environmentValues = company.environment
          ? Object.values(company.environment)
          : [];
        const socialValues = company.social
          ? Object.values(company.social)
          : [];
        const governanceValues = company.governance
          ? Object.values(company.governance)
          : [];
        rowData = [
          company.company_name,
          ...rowData,
          ...environmentValues,
          ...socialValues,
          ...governanceValues,
        ];
      } else {
        rowData = [
          company.company_name,
          ...rowData,
          ...Object.values(company[view] || {}),
        ];
      }

      // Fill in missing values with "-"
      if (rowData.length < generateHeaders().length) {
        const missingCount = generateHeaders().length - rowData.length;
        rowData = [...rowData, ...Array(missingCount).fill("-")];
      }

      return rowData;
    });

    return rows;
  };

  return (
    <Box
      paddingX={5}
      style={{
        width: mainDrawer ? "80vw" : "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: 10,
      }}
    >
      <div style={{ display: "flex", gap: 5 }}>
        {is_premium && (
          <CustomButton
            style={{ textTransform: "capitalize" }}
            color="primary"
            variant="text"
            startIcon={<LaunchIcon />}
          >
            <CSVLink
              style={{ textDecoration: "none" }}
              filename={"peerDataTable.csv"}
              data={[generateHeaders(), ...generateRowsCsv()]}
            >
              Export Table
            </CSVLink>
          </CustomButton>
        )}
        <Box
          style={{
            border: "1px solid #A1BFDF",
            borderRadius: 8,
            padding: 10,
            display: "flex",
            gap: 25,
            alignItems: "center",
            justifyContent: "center",
            color: "#6C6C6C",
            width: "35em",
          }}
        >
          <CustomButton
            onClick={() => setView("all")}
            variant={view === "all" ? "contained" : "text"}
            color={view === "all" ? "primary" : "default"}
            size={"small"}
            className={classes.actionButtons}
            style={{ color: view !== "all" ? "#6C6C6C" : "" }}
          >
            All
          </CustomButton>
          <CustomButton
            onClick={() => setView("environment")}
            variant={view === "environment" ? "contained" : "text"}
            color={view === "environment" ? "primary" : "default"}
            size={"small"}
            className={classes.actionButtons}
            style={{ color: view !== "environment" ? "#6C6C6C" : "" }}
          >
            Environment
          </CustomButton>
          |
          <CustomButton
            onClick={() => setView("social")}
            variant={view === "social" ? "contained" : "text"}
            color={view === "social" ? "primary" : "default"}
            size={"small"}
            className={classes.actionButtons}
            style={{ color: view !== "social" ? "#6C6C6C" : "" }}
          >
            Social
          </CustomButton>
          |
          <CustomButton
            onClick={() => setView("governance")}
            variant={view === "governance" ? "contained" : "text"}
            color={view === "governance" ? "primary" : "default"}
            size={"small"}
            className={classes.actionButtons}
            style={{ color: view !== "governance" ? "#6C6C6C" : "" }}
          >
            Governance
          </CustomButton>
        </Box>
      </div>

      <Box maxWidth={"100%"} className={`${classes.tableWrapper} normalScroll`}>
        <Table>
          <TableHead>
            <TableRow>
              {generateHeaders().map((header, index) => (
                <TableCell
                  key={index}
                  className={index === 0 ? classes.stickyFirstColumn : ""}
                  style={{
                    backgroundColor: "#EBF1F8",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#15314E",
                    minWidth: "300px",
                  }}
                >
                  {header.charAt(0).toUpperCase() + header.slice(1)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{generateRows()}</TableBody>
        </Table>
      </Box>
    </Box>
  );
};
export default PeerSectionTwo;
